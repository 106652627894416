import { BuProfileConstant } from "presentation/constant/BuProfile/BuProfileConstant";

const BUPROFILE_CONSTANT = BuProfileConstant.buProfile;

export const INITIAL_OPERATING_COMPANY_COL_DEF: any[] = [
    {
        headerName: BUPROFILE_CONSTANT.COMPANY_CODE,
        field: 'operatingCompany',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 160,
    },
    {
        headerName: BUPROFILE_CONSTANT.COMPANY_NAME,
        field: 'operatingCompanyName',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: BUPROFILE_CONSTANT.DOC_ABBR,
        field: 'operatingCompanyNameAbbr',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
]?.map((col, index) => ({ ...col, header: col.headerName, id: index + 1, dataType: 'text' }));